/**********/

@import "https://fonts.googleapis.com/css?family=Droid+Sans:300,400,500,600,700";
@import "https://fonts.googleapis.com/css?family=PT+Sans+Narrow+sansserif:300,400,500,600,700";
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');

.landingpage {
  font-family: 'Rubik', sans-serif;
}

.landingpage .body_menu {
  position: relative;
  width: 100%;
  font-family: 'Rubik', sans-serif;
}

.landingpage .sec_1_cont {
  margin-top: 10px;
  background-color: #fff;
}

/* -----------------header section-------------------- */
.landingpage .hero_image {
  background-image: url(./image/features/main_img.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 75rem;
  background-size: cover;

}

.landingpage .nav_head_text {
  color: white;
}

.landingpage .nav_head_text .head_text {
  font-size: 30px;
  font-weight: 600;
  margin: 0px;
}

.landingpage .main_image {
  width: 100%;
}

.landingpage .main_heading .heading_sec {
  font-size: 65px;
  margin-bottom: 10px;
  text-align: left;
  color: #263eac;
  justify-content: center;
  font-weight: 700;
}

.landingpage .heading_para {
  font-size: 22px;
  font-weight: 500;
  color: #000;
  margin-top: 20px;
  text-align: left;
}

.landingpage .sub_heading {
  color: #042A7C;
  font-family: 'Rubik', sans serif;
  font-size: 48px;
  line-height: 1.5em;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 700;
}

.landingpage .features-box-content h3 {
  font-weight: 600;
}

.landingpage .sub_heading::after {
  content: "";
  width: 90px;
  height: 4px;
  background: #263eac;
  display: block;
  margin-top: 18px !important;
  margin: 0px auto;
}

.landingpage .navbar_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  right: 0;
  width: 100%;
  z-index: 1;
  height: 70px;
  position: fixed;
  top: 0px;
  margin-top: -10px;
  background-color: #263eac;
}

.landingpage_content {
  margin-top: 70px;
}

.landingpage .nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  right: 0;
  list-style: none;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
}

.hamburger {
  display: none;
}


.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
}

.landingpage .nav-menu li a {
  color: white;
  padding-right: 25px;
  list-style: none;
  font-size: 20px;
  margin-top: 10px;
  font-weight: 500;
}

.landingpage .button_sect2 {
  box-shadow: -1px 2px 9px 0px rgb(74 74 74);
  font-weight: 600;
  font-size: 18px;
  border: 2px solid transparent;
  background-color: #f70;
  color: white;
  border-radius: 7px;
  padding: 0px 10px;
  cursor: pointer;
  text-align: center;
  transition: 0.6s;
  right: 0;
  margin-top: 5px;
}

.landingpage .login_btn {
  box-shadow: -1px 2px 9px 0px rgb(74 74 74);
  font-weight: 600;
  font-size: 18px;
  border: 2px solid transparent;
  background-color: #f70;
  color: white;
  border-radius: 7px;
  padding: 0px 10px;
  cursor: pointer;
  text-align: center;
  transition: 0.6s;
  right: 0;
  margin-right: 10px;
  margin-top: 5px;
}

.loginbtn {
  border-radius: 20px !important;
}

.landingpage .button_sect1 {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  font-weight: 600;
  font-size: 18px;
  padding: 16px 16px;
  border: 2px solid transparent;
  background-color: #263eac;
  color: white;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  transition: 0.6s;
}

.landingpage .card {
  box-shadow: -2px 1px 11px 0 rgb(0 0 0 / 20%);
  text-align: center;
  background-color: #ffffff;
  height: 280px;
  padding: 80px 15px 90px;
  border-radius: 15px;
}

.landingpage .cardpara {
  color: black;
  font-size: 18px;
  font-weight: 400;
}

.landingpage .feat_title_head {
  font-weight: 600;
}

.landingpage .feat_image {
  margin: auto;
  height: 70px !important;
  width: 70px !important;
  margin-top: -55px;
}

.landingpage .feat_container {
  padding: 30px;

}

.landingpage .client_sec {
  display: flex;
  margin-left: 200px;
  margin-right: 200px;
  margin-bottom: 30px;
  padding: 20px;
}

.landingpage .client_sec li {
  list-style: none;
  margin-right: 20px;

}

.landingpage .client_sec li img {
  width: 100%;
  border-radius: 20px;
}

.landingpage .bannersec {
  background: url(./image/features/bannerimg.png);
  color: white;
  padding: 20px;
  margin-top: 50px;
  height: 500px;
}

.landingpage .banner_para {
  font-size: 17px;
  margin-left: 10px;
  color: white;
  font-weight: 400;
  padding: 10px;
}

.landingpage .card_feature {
  box-shadow: rgb(125 125 125 / 35%) 1px -1px 10px;
  height: 350px;
  padding: 20px;
  border-radius: 20px;
}

.landingpage .icon_sec {
  color: #000d499e;
  font-size: 4rem;
  padding: 10px;
  background: -webkit-linear-gradient(#3399ff, #000066);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landingpage .para {
  color: black;
  font-size: 16px;
  text-align: justify;
}

.landingpage .features-box-content {
  font-size: 12px;
}

.content_sec {
  padding: 70px;
}

.content_sec .row {
  margin-bottom: 20px;
}

.landingpage .text_para {
  font-size: 18px;
  color: #000;
  font-weight: 500;
  text-align: left;
}

.landingpage .feat_title_sec1_4 {
  font-weight: 600;
  text-align: left;
  /* padding-left: 5px; */
  color: #263eac;
  font-size: 24px;
  margin-top: -10px;
  line-height: 40px;
}

.landingpage .sub_heading1 {
  color: black;
  font-size: 34px;
  font-weight: 600;
}

.landingpage .grid_sec {
  /* padding:50px; */
  margin-bottom: 20px;
}

.landingpage .grid_sec img {
  width: 100%;
  margin-top: 10px;
}

.landingpage .column_size {
  margin-top: 0px;
}

.landingpage .column_size1 {
  margin-top: 0px;
}

.landingpage .video_sec {
  width: 100%;
  height: 300px;
}

.landingpage .video_sec1 {
  text-align: center;
  margin-bottom: 20px;
}

.landingpage .cont_row {
  background: #263eac;
  padding-bottom: 50px;
  padding: 50px;
}

.landingpage .heading_cont {
  color: white;
  font-family: 'Rubik', sans serif;
  font-size: 36px;
  line-height: 1.5em;
  letter-spacing: 1px;
  font-weight: 600;
}

.landingpage .para1 {
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.landingpage .hamburger_btn {
  display: none;
}

/* ----------------------------------------------------------------------------------------------------------- */



@media screen and (max-width: 768px) {
  .landingpage .nav_head_text .head_text {
    font-size: 20px;
    font-weight: 600;
    margin: 0px;
  }

  .landingpage .card {
    margin-bottom: 30px;
  }

  .landingpage .nav-menu li a {
    padding-right: 0px;
  }

  .landingpage .login_btn {
    margin-bottom: 10px;
  }

  .landingpage_content {
    margin-top: 60px;
  }

  .landingpage .navbar_main {
    position: fixed;
    padding: 10px;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 60px;
    flex-direction: column;
    background-color: #727ba4;
    width: 50%;
    border-radius: 0 10px 10px 0;
    text-align: center;
    transition: 0.3s;
    box-shadow:
      0 10px 27px rgba(0, 0, 0, 0.05);

  }

  .nav-menu.active {
    left: 0;
    z-index: 1;
  }

  .nav-item {
    margin: 1rem 0;
  }

  .landingpage .body_menu {
    width: 100%;
  }

  .landingpage .hero_image {
    background-image: none;
    background-color: #263eac;
  }

  .landingpage .grid_sec img {
    width: 100%;
  }

  .landingpage .section_row {
    margin-bottom: -0px;
  }

  .landingpage .grid_sec {
    margin-bottom: 20px;
  }

  .landingpage .video_sec {
    width: 100%;
    height: 200px;
    margin-bottom: 30px;

  }

  .landingpage .client_sec {
    display: block;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    padding: 20px;
  }

  .landingpage .client_sec li {
    list-style: none;
    margin-right: 20px;
    padding-bottom: 20px;
  }

  .landingpage .cardpara {
    color: black;
    font-weight: 500;
    font-size: 15px;
  }

  .landingpage .features-box-content span {
    margin-left: 0px;
  }

  .landingpage .icon_sec {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0px !important;
  }

  .landingpage .feat_title_sec1_4 {
    font-weight: 600;
    text-align: center;
    color: #263eac;
    font-size: 20px;
    margin-top: -10px;
    line-height: 40px;
  }

  .track-row-img {
    order: 1;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    -webkit-transform: translateY(8px) rotate(45deg);
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    -webkit-transform: translateY(-8px) rotate(-45deg);
    transform: translateY(-8px) rotate(-45deg);
  }

  .landingpage .main_heading .heading_sec {
    font-size: 48px;
    margin-bottom: 10px;
    text-align: center;
    justify-content: center;
    font-weight: 700;
  }

  .landingpage .main_image {
    display: none;
  }

  .landingpage .heading_para {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
  }

  .landingpage .sub_heading {
    color: #042A7C;
    font-family: 'Rubik', sans serif;
    font-size: 30px;
    line-height: 1.5em;
    letter-spacing: 1px;
    text-align: center;
    font-weight: 700;
  }

  .landingpage .hamburger_btn {
    display: block;
  }

  .landingpage .feat_container {
    margin-top: 30px;
  }

  .landingpage .main_heading {
    text-align: center;
    color: white;
    font-family: 'Rubik';
    font-size: 5rem;

  }

  .landingpage .bannersec {
    background: #263eac;
    color: white;
    padding: 20px;
    margin-top: 50px;
    height: 40%;
  }

  .landingpage .para {
    color: black;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    margin-left: 0px;
    margin-right: 0px;
  }

  .content_sec {
    padding: 20px;
  }

  .landingpage .features-box-content {
    font-size: 12px;
    margin-left: 0px;
    text-align: center;
    margin-bottom: 30px !important;
    height: 355px !important;
  }

  .landingpage .cont_row {
    background: #263eac;
  }
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------- */





@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .nav-menu {
    position: fixed;
    left: -100%;
    top: 60px;
    flex-direction: column;
    background-color: #727ba4;
    width: 50%;
    border-radius: 0 10px 10px 0;
    text-align: center;
    transition: 0.3s;
    box-shadow:
      0 10px 27px rgba(0, 0, 0, 0.05);

  }

  .landingpage .client_sec li img {
    width: 100%;
    border-radius: 20px;
  }

  .landingpage .client_sec {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    padding: 20px;
  }

  .nav-menu.active {
    left: 0;
    z-index: 1;
  }

  .nav-item {
    margin: 1rem 0;
  }

  .landingpage .body_menu {
    width: 100%;
  }


  .landingpage .hero_image {

    background-image: none;
    background-color: #263eac;
  }

  .landingpage .grid_sec img {
    width: 100%;
  }

 

  .landingpage .section_row {
    margin-bottom: -0px;
  }


  .landingpage .video_sec {
    width: 100%;
    height: 300px;
    margin-bottom: 30px;
  }

  .landingpage .client_sec li {
    list-style: none;
    margin-right: 0px;
    padding-bottom: 20px;
    margin-right: 10px;
  }

  .landingpage .features-box-content span {
    margin-left: 0px;
  }

  .landingpage .icon_sec {
    color: #263eac;
    font-size: 12px;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    -webkit-transform: translateY(8px) rotate(45deg);
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    -webkit-transform: translateY(-8px) rotate(-45deg);
    transform: translateY(-8px) rotate(-45deg);
  }
}