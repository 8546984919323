.wrapper .container,
.wrapper .container-fluid {
  margin-bottom: 100px;
  margin-top: 10px;
}

.container-fluid1 {
  margin-bottom: 0 !important;
}

.wrapper .content11 .collapselg {
  width: calc(100% - 20%);
}

.inv_HeaderCls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.inv_HeaderCls h3 {
  margin: 0;
  font-weight: bold;
}

.two_HeaderCls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.two_HeaderCls h3 {
  margin: 0px;
  text-align: center;
  flex: 50%;
}

.info-box {
  display: block;
  min-height: 90px;
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

.location_lable {
  margin-right: 10px;
  margin-left: 10px;
}

.info-box-text {
  font-size: 15px;
  color: #e4f3f7;
}

.info-box-content {
  padding: 5px 10px;
  margin-left: 90px;
}

.info-box-number {
  display: block;
  font-size: 23px;
  font-weight: 100;
}

.info-currency {
  font-size: 15px;
}

.info-box-icon {
  border-top-left-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
  display: block;
  float: left;
  height: 90px;
  width: 90px;
  text-align: center;
  font-size: 45px;
  line-height: 90px;
  background: rgba(0, 0, 0, 0.2);
}

.infobox1_1 {
  border-radius: 10px 10px 0px 0px;
  background-image: linear-gradient(120deg,
      rgb(150, 149, 117) 0%,
      rgba(13, 95, 136, 0.76) 100%);
}

.info-box_sec {
  display: block;
  min-height: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  margin-bottom: 15px;
  background-color: aquamarine;
}

.info-box-text_sec {
  font-size: 11px;
  padding: 0px 5px;
}

.info-box-number_sec {
  float: right;
  padding-right: 5px;
  font-size: 16px;
}

.info-box-icon_sec {
  font-size: 10px;
  margin-left: -5px;
}

.infobg1 {
  background-image: linear-gradient(120deg, #83dfe4 0%, #31708fc2 100%);
  border-radius: 0px 0px 8px 8px;
}

.btn-primary {
  background-color: #263eac;
}

.column_4box {
  float: left;
  width: 25%;
  padding: 10px;
}

/* mobile view is importan MVI for .column_4box */
.column_4box p {
  color: white;
}

.span_4box {
  display: block;
}

.gly_plus {
  margin-top: 40px;
  float: right;
  top: 10;
  font-size: 40px;
  color: white;
}

.Dashboard_sub_head {
  font-size: 16px;
  font-family: poppins;
  font-weight: 600;
  color: #263eac;
}

.sec1_dash {
  margin-top: -20px;
}

.minimize_btn {
  float: right;
  margin-top: -30px;
  background-color: rgba(0, 0, 0, 0.5);
}

.title_livedata {
  display: inline-block;
  width: 100%;
}

.dash_site_sel {
  padding: 10px;
  width: 250px;
  float: right;
  font-weight: 600;
  /* RAMYA 24-03-2022 */
  color: #263eac;
}

@media only screen and (max-width: 768px) {
  .inv_HeaderCls h3 {
    margin: 0 auto 0;
  }

  .column_4box {
    float: left;
    width: 50%;
    padding: 10px;
  }

  .Dashboard_sub_head {
    font-size: 14px;
    font-family: poppins;
    font-weight: 400;
  }
}

.btn_max_min {
  border: none;
  background-color: white;
  float: right;
  margin-top: -30px;
}

.glyicon_style {
  color: #ffd195;
}

/*side menu add emp btn*/

.sidebar_quick_menu_c {
  height: 250px;
  width: 10px;
  position: fixed;
  margin-left: 10px;
  right: 60px;
  top: 35%;
  z-index: 31;
}

.sidebar_quick_menu_c a {
  text-decoration: none !important;
  vertical-align: middle;
  line-height: 3;
  list-style: none;
}

.sidebar_quick_menu_c p {
  color: white;
  position: relative;
  display: inline-flex;
  margin-top: 10px;
  margin-left: 5px;
}

.sidebar_quick_menu_c p i {
  left: 20px;
  position: relative;
  vertical-align: middle;
  text-align: center;
  font-size: 24px;
}

.sidebar_quick_menu_c p .s_icon_title {
  width: 150px;
  margin-left: -30px;
}

.social i {
  margin-right: 80px;
}

.sidebar_quick_menu_c .social {
  margin-right: 500px;
  width: 230px;
  padding: 0;
  display: inline-table;
  height: 0px;
  background-color: #263eac;
  -moz-transition-property: margin-left;
  -moz-transition-duration: 0.2s;
  -moz-transition-delay: 0.2s;
  -ms-transition-property: margin-left;
  -ms-transition-duration: 0.2s;
  -ms-transition-delay: 0.2s;
  -o-transition-property: margin-left;
  -o-transition-duration: 0.2s;
  -o-transition-delay: 0.2s;
  -webkit-transition-property: margin-left;
  -webkit-transition-duration: 0.2s;
  -webkit-transition-delay: 0.2s;
  box-shadow: 0px 0px 6px 0px #3e3d3d;
  cursor: pointer;
}

.sidebar_quick_menu_c .social:hover {
  margin-left: -150px;
  width: 230px;
  background-color: #3d494e;
  text-decoration: none;
}

.sidebar_quick_menu_c .add_emp:hover {
  background-color: #01a941db;
  text-decoration: none;
}

.sidebar_quick_menu_c .loc_emp:hover {
  background-color: #db501f;
  text-decoration: none;
}

.sidebar_quick_menu_c .msg_center:hover {
  background-color: #caa705;
  text-decoration: none;
}

.sidebar_quick_menu_c .app_conf:hover {
  background-color: #b20670;
  text-decoration: none;
}

/* new changes by ramya  */
.react-dropdown-tree-select .dropdown .dropdown-trigger {
  border: none !important;
}

.btn-20 {
  margin-top: 25px;
}

.flex-align {
  display: flex;
}

.invoiceType label {
  margin-right: 10px;
}

th,
td {
  padding: 0px 10px;
}

input[type="checkbox"],
input[type="radio"] {
  margin-right: 5px !important;
}

.btnfirst {
  margin-right: 10px;
}

a[aria-expanded="false"]::before,
a[aria-expanded="true"]::before {
  display: none;
}

.tag-item {
  margin: 0px !important;
}

.react-dropdown-tree-select .dropdown {
  width: 100% !important;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.tag-item .search {
  border-bottom: none !important;
}

.mandatory-fields {
  color: red;
  font-weight: 600;
}

.HeaderCls,
.repot_sub_dwldbtn_cls_daily {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.taskname {
  text-align: center;
  margin-top: -20px;
  margin-bottom: 0;
}

.SelectSearch-div {
  width: 230px;
}

.p-left {
  padding-left: 20px;
}

.rolelabel {
  font-size: 16px;
  margin: 10px 10px 0 0;
}

.role-div label span {
  color: red;
}

.d-flex {
  display: flex !important;
}

.report_submenus {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.HeaderCls h3 {
  margin: 0px;
}

.site_dropstyle {
  width: 250px;
}

.react-date-picker__wrapper {
  background-color: #fff;
  border: 1px solid #ccc !important;
  padding: 6px;
  margin-right: 20px;
  height: 36px;
}

.container-fluid .container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}

.inline-flex {
  display: inline-flex;
}

.mt-20 {
  margin-top: 20px;
}

.inv_list_cls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.form-mb-20 {
  margin-bottom: 20px;
}

.nav-tabs>li {
  font-weight: bold;
  color: black;
  cursor: pointer;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
  font-weight: bold;
  color: black;
}

.card .card-body {
  padding: 15px !important;
}

.created,
.deleted,
.closed,
.completed,
.finished,
.inprogress,
.orange {
  font-weight: 400;
  border-radius: 20px;
  color: #fff;
  padding: 0px 10px;
  width: 95px;
  text-align: center;
}

.created {
  /*  background-color: blue; */
  background-color: #3059b8;
}

.deleted {
  /* background-color: red; */
  background-color: #d81212;
}

.closed {
  /* background-color: rgb(222, 46, 178); */
  background-color: #e75d08cf;
}

.completed {
  /* background-color: rgb(45, 217, 144); */
  background-color: rgb(10 136 83);
}

.orange {
  background-color: rgb(255 152 0);
}

.finished {
  /* background-color: green; */
  background-color: #189118;
}

.inprogress {
  /* background-color: rgb(147, 34, 145); */
  background-color: rgb(69 42 178);
}

.nav-tabs>li>a {
  color: #000;
  font-weight: bold;
}

.slide-pane__subtitle {
  font-size: 12px;
  margin-top: 0px !important;
}

.slide-pane__title-wrapper {
  margin-top: -15px !important;
}

.pagination>li>a,
.pagination>li>span {
  border: none !important;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  background-color: #263eac;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
  color: #263eac;
  background-color: #263eac30;
}

@media only screen and (max-width: 786px) {
  .flex-align {
    display: block;
  }

  .btn-group {
    width: 100%;
  }

  .btn-20 {
    margin-top: 10px;
  }

  .site_dropstyle {
    width: 100%;
    margin: 10px 0px;
  }

  .inv_list_cls {
    display: block;
    margin-bottom: 10px;
  }
}

.booking-id-a {
  color: blue;
  text-decoration: underline;
}

.label-font-size {
  font-size: 23px;
}

.width-100 {
  width: 100%;
}

.reactTable-marginBottom {
  margin-bottom: 5%;
}

.Tooltip-div {
  float: right;
  padding-top: 2px;
  margin: auto;
}

.modal-main-div {
  padding-top: 100px;
}

.modal-xl-width-80 {
  width: 80% !important;
}


.modal-body-container {
  margin-bottom: 5%;
  padding-top: 0;
  width: 100%;
}

.slidingpane-marginbottom {
  margin-bottom: 10px;
}

.tax-choice-btn {
  margin-right: 5px;
}

.table-margin-auto {
  margin: auto;
}
