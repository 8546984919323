@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700);

#prog-bar-cont {
  margin-top: 15px;
  margin-bottom: -12px;
  background: gainsboro;
  width: 230px;
  height: 28px;
  padding: 0.5em;
  position: relative;
  top: 50%;
  left: 40%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50px;
  box-sizing: border-box;
  box-shadow: 0 0 1px 0 rgba(#000, 0.75) inset, 0 0 4px 0 rgba(#000, 0.5);
  #prog-bar {
    background: #000;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset,
      0 0 1px 3px rgba(white, 0.75) inset;
    background: transparent;
    &:before {
      border-radius: 50px;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 0 1px rgba(#000, 0.3) inset,
        0 0 5px 2px rgba(#fff, 0.8) inset;
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      width: 96%;
      left: 50%;
      margin-left: -48%;
      border-radius: 20px;
      height: 10px;
      background: linear-gradient(
        to bottom,
        rgba(white, 0.85) 30%,
        transparent 120%
      );
    }
  }
  &:hover {
    #prog-bar {
      #background {
        -webkit-clip-path: inset(0 0 0 0);
        clip-path: inset(0 0 0 0);
      }
    }
  }
}

#prog-bar-cont-1 {
  margin-top: 15px;
  margin-bottom: -12px;
  background: gainsboro;
  width: 130px;
  height: 30px;
  padding: 5px;
  position: relative;
  top: 50%;
  left: 75px;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50px;
  box-sizing: border-box;
  box-shadow: 0 0 1px 0 rgba(#000, 0.75) inset, 0 0 4px 0 rgba(#000, 0.5);
  #prog-bar-1 {
    background: #000;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset,
      0 0 1px 3px rgba(white, 0.75) inset;
    background: transparent;
    &:before {
      border-radius: 50px;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 0 1px rgba(#000, 0.3) inset,
        0 0 5px 2px rgba(#fff, 0.8) inset;
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      width: 96%;
      left: 50%;
      margin-left: -48%;
      border-radius: 20px;
      height: 10px;
      background: linear-gradient(
        to bottom,
        rgba(white, 0.85) 30%,
        transparent 120%
      );
    }
  }
  &:hover {
    #prog-bar {
      #background {
        -webkit-clip-path: inset(0 0 0 0);
        clip-path: inset(0 0 0 0);
      }
    }
  }
}

#prog-bar-cont-2 {
  margin-top: 15px;
  margin-bottom: -12px;
  background: gainsboro;
  width: 130px;
  height: 30px;
  padding: 5px;
  position: relative;
  top: 50%;
  left: 75px;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50px;
  box-sizing: border-box;
  box-shadow: 0 0 1px 0 rgba(#000, 0.75) inset, 0 0 4px 0 rgba(#000, 0.5);
  #prog-bar-2 {
    background: #000;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset,
      0 0 1px 3px rgba(white, 0.75) inset;
    background: transparent;
    &:before {
      border-radius: 50px;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 0 1px rgba(#000, 0.3) inset,
        0 0 5px 2px rgba(#fff, 0.8) inset;
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      width: 96%;
      left: 50%;
      margin-left: -48%;
      border-radius: 20px;
      height: 10px;
      background: linear-gradient(
        to bottom,
        rgba(white, 0.85) 30%,
        transparent 120%
      );
    }
  }
  &:hover {
    #prog-bar {
      #background {
        -webkit-clip-path: inset(0 0 0 0);
        clip-path: inset(0 0 0 0);
      }
    }
  }
}

#prog-bar-cont-3 {
  margin-top: 15px;
  margin-bottom: -12px;
  background: gainsboro;
  width: 130px;
  height: 28px;
  padding: 0.5em;
  position: relative;
  top: 50%;
  left: 75px;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50px;
  box-sizing: border-box;
  box-shadow: 0 0 1px 0 rgba(#000, 0.75) inset, 0 0 4px 0 rgba(#000, 0.5);
  #prog-bar-3 {
    background: #000;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset,
      0 0 1px 3px rgba(white, 0.75) inset;
    background: transparent;
    &:before {
      border-radius: 50px;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 0 1px rgba(#000, 0.3) inset,
        0 0 5px 2px rgba(#fff, 0.8) inset;
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      width: 96%;
      left: 50%;
      margin-left: -48%;
      border-radius: 20px;
      height: 10px;
      background: linear-gradient(
        to bottom,
        rgba(white, 0.85) 30%,
        transparent 120%
      );
    }
  }
  &:hover {
    #prog-bar {
      #background {
        -webkit-clip-path: inset(0 0 0 0);
        clip-path: inset(0 0 0 0);
      }
    }
  }
}

.col-prograss-bar {
  justify-content: center;
  display: flex;
  align-items: center;
}

.mt-25 {
  margin-top: 25px;
}

@media only screen and (max-width: 768px) {
  #prog-bar-cont {
    width: 100%;
    left: 50%;
  }
}

@media only screen and (max-width: 992px) {
  #prog-bar-cont {
    width: 100%;
    left: 50%;
  }
}

@media only screen and (max-width: 1115px) {
  #prog-bar-cont {
    width: 100%;
    left: 50%;
  }
}
