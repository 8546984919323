.slide-pane__header {
  margin-top: 52px !important;
  background: #263eac;
  color: #fff;
}

.slide_pane_mail_view_c_invlistdiv .slide-pane__header_mail_view {
  margin-top: 0px !important;
}

.ser_reg_info a {
  margin-right: 10px;
  color: #263eac;
  text-decoration: underline;
}
.GrDocumentUser {
  color: #263eac !important;
}
.ser_reg_info a span {
  margin-right: 10px;
}

@media only screen and (max-width: 786px) {
  .slide-pane__header {
    margin-top: 87px !important;
  }

  .slide_pane_mail_view_c_invlistdiv .slide-pane__header_mail_view {
    margin-top: 0px !important;
  }
  .row-bot {
    margin-bottom: 50px;
    padding-bottom: 50px;
  }
  .ser_reg_info {
    padding: 20px 0px 0px; /* RAMYA 18/MAR/2022 */
  }
  .ser_reg_info a {
    display: block;
  }
}
.Irt_col {
  margin: 10px 0px;
}

.uploadimage {
  font-size: 25px;
  background-color: transparent;
  border: none;
  color: #263eac;
}
.imgshow {
  padding: 20px 0px;
  display: flex;
  flex-wrap: wrap;
}
.column {
  margin-right: 10px;
  margin-top: 10px;
}
.image-item__btn-wrapper {
  padding-top: 10px;
  text-align: center;
}
.rowbtn {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 70px;
}
.image-item__btn-wrapper button {
  border: 1px solid #263eac;
  background-color: #fff;
  border-radius: 5px;
}
.viewimg {
  color: #263eac;
  font-size: 15px;
}

.ser-div {
  float: right;
  margin-right: 20px;
}

.color-red {
  color: red;
}

.form-input-width {
  width: 100%;
}

.email-font-weight {
  font-weight: 200;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-bold {
  font-weight: bold;
}

.margin-right-10 {
  margin-right: 10px;
}

.row-margin-top {
  margin-top: 10px;
}

.btn-margin-right-5 {
  margin-right: 5px;
}

.row-margin-left-2 {
  margin-left: 2px;
}
