collapsed_group.panel-group {
  margin-top: 30px;
}


.collapsed_group .panel-collapse {
  max-height: 500px;
  overflow: hidden;
  transition: max-height .5s ease;
}

.collapsed_group .panel-close {
  max-height: 0;
}

.jobcard_created_text {
  color: blue;
}

/* color:#f07925;*

  *.jobcard_created_text:hover{
    color:white;
  }*/

.collapsed_group .panel-heading a:before {
  display: none !important;
}

.panel {
  background-color: transparent !important;
}

.panel-default>.panel-heading {
  background-color: transparent !important;
  padding: 0px !important;
}

.panel-group .panel {
  border-radius: 0px !important;
}

.panel-title a {
  display: flex;
  align-items: center;
  color: #263eac;
}

.panel-title a>div.icons {
  margin-right: 20px;
}

.panel-title>a,
.panel-title>a:active {
  padding: 10px;
  text-transform: none;
  /* padding: 0px 35px 8px 25px!important; CHANGED BY RAMYA 24-03-2022 */
  font-size: 14px;
}

.panel-group {
  margin-bottom: 0px !important;
  background: transparent !important;
}

.panel-default {
  border: none !important;
}

span.pro-item-content {
  color: #fff;
  font-weight: 400;
  font-size: 15px;
}

div.pro-inner-item1 {
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
}

.pro-sidebar.collapsed>nav.pro-menu.active {
  display: none;
}

.pro-sidebar nav.pro-menu {
  display: block;
}

.pro-sidebar.collapsed .panel-heading:hover>.panelcontent .collapsed_group .panel-collapse {
  transition: visibility, transform .3s;
  visibility: visible;
  height: auto !important;
  position: fixed;
  left: 90px;
  visibility: visible;
  min-width: 220px;
  max-width: 270px;
  background-color: transparent;
  max-height: 100%;
}

.pro-sidebar.collapsed .pro-sidebar .pro-menu .pro-menu-item:hover .collapsed_group .panel-close {
  max-height: 0;
}

.pro-sidebar.collapsed .collapsed_group .panel-collapse {
  height: auto !important;
  position: fixed;
  left: 80px;
  visibility: visible;
  min-width: 220px;
  max-width: 270px;
  background-color: transparent;
  max-height: 100%;
  margin-top: -40px;
  border-left: 5px solid #263eac;
}

.pro-sidebar.collapsed .collapsed_group .panel-close {
  display: none;
}

.pro-sidebar.collapsed .collapsed_group ul.list-group {
  margin-left: 20px !important;
}

span.pro-item-content {
  font-size: 14px !important;
}

ul.list-group {
  margin-left: 50px !important;
}

ul.list-group1 {
  margin-left: 0px !important;
}

li.list-group-item {
  color: #263eac !important;
  padding: 5px 10px !important;
  border: none !important;
}

li.list-group-item1 {
  padding: 5px 0px !important;
}

.panel-title1 {
  font-size: 18px;
  text-align: center;
  color: #263eac !important;
}

.panel-title1 a {
  color: #263eac !important;
}
