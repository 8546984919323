.display-none {
  display: none;
}

.form-color-black {
  color: black;
}

.glyphicon-styles-css {
  border: none;
  padding: 6px 7px 5px 7px;
  font-size: 1em;
  border-radius: 18px;
  background-color: #5881d2;
}

.glyphicon-trash-styles-css {
  border: none;
  padding: 6px 7px 5px 7px;
  font-size: 1em;
  border-radius: 18px;
  background-color: tomato;
}

.span-section-icons {
  font-size: 1em;
  color: white;
}

.updatedevice-div-style {
  text-align: center;
  margin-right: 10px;
}

.display-inline-flex {
  display: inline-flex;
}

/* CustomerComponents styles */

.input-width-100 {
  width: 100%;
}

.row-second-div {
  margin-top: 10px;
}

.row-thrid-div {
  margin-bottom: 30px;
}
