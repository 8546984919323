.landingpage .icon_sec {
  font-size: 60px
}

.landingpage .features-box-content {
  margin-bottom: 20px;
  height: 315px;
  border-radius: 20px;
  padding: 30px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.landingpage .para {
  color: black;
  font-size: 16px;
  text-align: justify;
  font-weight: 400;
  display: flex;
  justify-content: center;
}

.landingpage .icon_sec {
  color: #263eac;
  font-size: 60px;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding: 10px;
  background: -webkit-linear-gradient(#3399ff, #000066);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landingpage .banner_para {
  font-size: 17px;
  margin-left: 10px;
  color: white;
  font-weight: 400;
  padding: 0 0 10px 0;
}


.landingpage .card {
  box-shadow: -2px 1px 11px 0 rgb(0 0 0 / 20%);
  /* padding: 0px 16px; */
  text-align: center;
  background-color: #ffffff;
  height: 280px;
  padding: 80px 15px 90px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.card-three {
  box-shadow: -2px 1px 11px 0 rgb(0 0 0 / 20%);
  text-align: center;
  background-color: #cccccc;
  height: 390px;
  padding: 20px 20px 10px;
  border-radius: 20px;
  margin-bottom: 20px;

}

.nav-link {
  margin-top: 20px;
}

.cardhover:hover {
  border-bottom: 3px solid #263eac;
  cursor: pointer;
}

.landingpage .cardpara {
  color: black;
  font-size: 18px;
  font-weight: 300;
}

.landingpage .feat_title_head {
  font-weight: 900;
}


.landingpage .features-box-content h3 {
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.features-box-content span {
  display: block !important;
}


@media screen and (max-width: 768px) {
  .landingpage .client_sec li img {
    width: 50% !important;
  }

  .Throughapp-logo,
  .Android-logo {
    width: 200px;
  }


  .res-center {
    margin-left: auto !important;
    margin-right: auto !important;
    align-items: center;
    text-align: center;
    justify-content: center;

  }

}


@media only screen and (min-width: 768px) and (max-width: 992px) {

  .Throughapp-logo,
  .Android-logo {
    width: 200px !important;
  }

}






/* @import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:200,900');

:root {
  --text-color: hsla(210, 50%, 85%, 1);
  --shadow-color: hsla(210, 40%, 52%, .4);
  --btn-color: hsl(210, 80%, 42%);
  --bg-color: #141218;
}

.bubbles-btn {
  position:relative;
  padding: 10px 20px;  
  border: none;
  background: none;
  cursor: pointer;
  
  font-family: "Source Code Pro";
  font-weight: 900;
  text-transform: uppercase;
  font-size: 30px;  
  color: var(--text-color);
  
  background-color: var(--btn-color);
  box-shadow: var(--shadow-color) 2px 2px 22px;
  border-radius: 100px; 
  z-index: 0;  
  overflow: hidden;   
}

.bubbles-btn:focus {
  outline-color: transparent;
  box-shadow: var(--btn-color) 2px 2px 22px;
}

.bubbles-btn::after {
  content: var(--content);
  display: block;
  position: absolute;
  white-space: nowrap;
  padding: 40px 40px;
  pointer-events:none;
}

.bubbles-btn::after{
  font-weight: 200;
  top: -30px;
  left: -20px;
} 


.bubbles-btn::before {
  content: '';
  pointer-events: none;
  opacity: .6;
  background:
    radial-gradient(circle at 20% 35%,  transparent 0,  transparent 2px, var(--text-color) 3px, var(--text-color) 4px, transparent 4px),
    radial-gradient(circle at 75% 44%, transparent 0,  transparent 2px, var(--text-color) 3px, var(--text-color) 4px, transparent 4px),
    radial-gradient(circle at 46% 52%, transparent 0, transparent 4px, var(--text-color) 5px, var(--text-color) 6px, transparent 6px);

  width: 100%;
  height: 300%;
  top: 0;
  left: 0;
  position: absolute;
  animation: bubbles 5s linear infinite both;
}

@keyframes bubbles {
  from {
    transform: translate();
  }
  to {
    transform: translate(0, -66.666%);
  }
} */




/* animation button */

#wrap {
  margin: 20px auto;
  text-align: center;
}

.btn-slide {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 200px;
  line-height: 50px;
  padding: 0;
  border-radius: 50px;
  background: #fdfdfd;
  border: 2px solid #0099cc;
  margin: 10px;
  transition: .5s;
}

.btn-slide2 {
  border: 2px solid #efa666;
  position: relative;
  display: inline-block;
  height: 50px;
  width: 200px;
  line-height: 50px;
  padding: 0;
  border-radius: 50px;
  /* background: #ffffff9e; */
  margin: 10px;
  transition: .5s;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.btn-slide:hover {
  background-color: #0099cc;
}

.btn-slide2:hover {
  background-color: #ff7700;
}

.btn-slide:hover span.circle,
.btn-slide2:hover span.circle2 {
  left: 100%;
  margin-left: -45px;
  background-color: #fdfdfd;
  color: #0099cc;
}

.btn-slide2:hover span.circle2 {
  color: #ff7700;
}

.btn-slide:hover span.title,
.btn-slide2:hover span.title2 {
  left: 40px;
  opacity: 0;
}

.btn-slide:hover span.title-hover,
.btn-slide2:hover span.title-hover2 {
  opacity: 1;
  left: 40px;
}

.btn-slide span.circle,
.btn-slide2 span.circle2 {
  display: block;
  background-color: #0099cc;
  color: #fff;
  position: absolute;
  float: left;
  margin: 5px;
  line-height: 42px;
  height: 40px;
  width: 40px;
  top: 0;
  left: 0;
  transition: .5s;
  border-radius: 50%;
}

.btn-slide2 span.circle2 {
  background-color: #ff7700;
}

.btn-slide span.title,
.btn-slide span.title-hover,
.btn-slide2 span.title2,
.btn-slide2 span.title-hover2 {
  position: absolute;
  left: 65px;
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
  color: #30abd5;
  transition: .5s;
}

.btn-slide2 span.title2,
.btn-slide2 span.title-hover2 {
  color: #ff7700;
  top: -8px;
}

.btn-slide span.title-hover,
.btn-slide2 span.title-hover2 {
  left: 80px;
  opacity: 0;
}

.btn-slide span.title-hover,
.btn-slide2 span.title-hover2 {
  color: #fff;
}


.fa-check-circle {
  color: white;
  margin-right: 5px;
}

@media screen and (max-width: 880px) {
  .sub_heading {
    line-height: 1em;
    margin-top: 0;
  }
}

@media screen and (max-width: 880px) {
  .btn-slide2 {
    width: 180px !important;
  }

  .title2 {
    left: 45px !important;
  }
}




.cir-dow {
  width: 28px !important;
  height: 25px !important;
}

.btn-donwload {
  margin-top: 18px !important;
  height: 36px !important;
}

.fa-rocket {
  font-size: 17px;
  text-align: center;
  justify-content: center;
  display: flex;
  margin-top: 12px;

}

.fa-download {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 5px !important;
}




@media only screen and (min-width: 768px) and (max-width: 990px) {
  .features-box-content {
    height: 395px !important;

  }
}



@media only screen and (max-width: 568px) {
  .footer-ul {
    margin-left: 75px !important;

  }
}


@media only screen and (max-width: 767px) {
  .footer-ul {
    margin-left: 175px !important;

  }
}


@media only screen and (max-width: 610px) {
  .footer-ul {
    margin-left: 75px !important;

  }
}

@media only screen and (max-width: 400px) {
  .footer-ul {
    margin-left: 0 !important;

  }
}






/* footer */

.footer-section {
  background-color: #f6f6f8;
}

.footer-ul {
  list-style: none;
}

.footer-ul li {
  margin-bottom: 10px;
  display: flex;
  margin-left: 30px;
}

.footer-ul li img {
  margin-right: 20px;
}

.footer-ul li span {
  font-size: 16px;
  font-weight: 600;
}

.Throughapp-logo,
.Android-logo {
  width: 300px;
}

.icons-sec {
  width: 30px;
  height: 30px;
}

.get-in {
  font-size: 36px;
  font-weight: 800;
}


.font-wet {
  font-weight: 600;

}



/* footer social media icons */


textarea {
  resize: none;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  white-space: nowrap;
}

.rounded-social-buttons .social-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 3.125rem;
  height: 3.125rem;
  border: 0.125rem solid transparent;
  padding: 0;
  text-decoration: none;
  text-align: center;
  color: #fefefe;
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 2em;
  border-radius: 1.6875rem;
  transition: all 0.5s ease;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.rounded-social-buttons .social-button:hover,
.rounded-social-buttons .social-button:focus {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.rounded-social-buttons .fa-twitter,
.fa-facebook-f,
.fa-linkedin,
.fa-youtube,
.fa-instagram {
  font-size: 17px;
}

.rounded-social-buttons .social-button.facebook {
  background: #3b5998;
}

.rounded-social-buttons .social-button.facebook:hover,
.rounded-social-buttons .social-button.facebook:focus {
  color: #3b5998;
  background: #fefefe;
  border-color: #3b5998;
}

.rounded-social-buttons .social-button.twitter {
  background: #55acee;
}

.rounded-social-buttons .social-button.twitter:hover,
.rounded-social-buttons .social-button.twitter:focus {
  color: #55acee;
  background: #fefefe;
  border-color: #55acee;
}

.rounded-social-buttons .social-button.linkedin {
  background: #007bb5;
}

.rounded-social-buttons .social-button.linkedin:hover,
.rounded-social-buttons .social-button.linkedin:focus {
  color: #007bb5;
  background: #fefefe;
  border-color: #007bb5;
}

.rounded-social-buttons .social-button.youtube {
  background: #bb0000;
}

.rounded-social-buttons .social-button.youtube:hover,
.rounded-social-buttons .social-button.youtube:focus {
  color: #bb0000;
  background: #fefefe;
  border-color: #bb0000;
}

.rounded-social-buttons .social-button.instagram {
  /* background: #125688; */
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

.rounded-social-buttons .social-button.instagram:hover,
.rounded-social-buttons .social-button.instagram:focus {
  color: #125688;
  background: #fefefe;
  border-color: #125688;
}


.copy-text {
  text-align: center;
}