.noData-styles {
    display: block;
    position: absolute;
    left: 50%;
    top: 60%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    transition: all .3s ease;
    z-index: 1;
    pointer-events: none;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
}

.nodata-img {
    width: 180px;
    height: 200px;
}

.nodate-text {
    color: #263eac;
    font-weight: 700;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
