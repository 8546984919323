.back_btn_cm_lg {
  background-color: rgb(5, 164, 181);
  color: white;
  float: none;
  display: inline-block;
  margin-left: 5px;
  border-radius: 5px;
  padding: 3px 7px;
  margin-top: 13px;
}

.reactIcon_Dcls {
  text-align: right;
}

.reactIcon_Dcls ul {
  margin-bottom: 0px !important;
}

.reactIcon_Btn {
  list-style: none;
  display: inline-flex;
}

.reactIcon_Btn li,
.inlineicon {
  color: #263eac;
  width: fit-content;
  background-color: #263eac30;
  cursor: pointer;
  font-size: 25px;
  padding: 4px;
  margin: 3px;
  line-height: 0px;
  border-radius: 7px;
  box-shadow: -1px 1px 1px 2px #fff, -3px 0px 20px 0 rgb(34 33 81 / 1%),
    2px 0px 9px 0 rgb(34 33 81 / 23%);
  border: 1px solid aliceblue;
}

.reactIcon_Btn_txt {
  list-style: none;
  display: inline-flex;
}

.reactIcon_Btn_txt li {
  background-color: #f9f9fd;
  font-size: 25px;
  padding: 5px;
  line-height: 0px;
  vertical-align: middle;
  border: 1px solid aliceblue;
  color: #263eac;
}

.btn-default {
  background: #263eac !important;
  color: #fff !important;
}

.viewstatus {
  margin-top: -20px;
  padding: 10px;
  width: 200px;
  text-align: center;
  border-radius: 20px;
}

.icons-size-col {
  font-size: 16px !important;
  color: #263eac !important;
}

.site_dropstyle_JobCard,
.site_dropstyle {
  width: 250px !important;
}

@media only screen and (max-width: 768px) {
  .site_dropstyle_JobCard,
  .site_dropstyle {
    width: 100%;
  }

  .reactIcon_Btn {
    margin-top: 10px;
    padding: 0;
  }

  .icons-size-col {
    font-size: 16px !important;
  }
}
