.mainModuleName {
  color: "blue";
  font-weight: "600";
}

.glb_search_btn {
  border: 2px solid rgba(3, 123, 222, 0);
  padding: 2px 15px;
  border-radius: 15px;
  width: 85px;
  transition: 0.5s;
}

.glb_search_btn:focus {
  border: 1px solid #ffffff00;
  outline: none;
}

.glb_search_btn:hover {
  width: 180px;
  transition: width 1s;
}

.searchresult_cls {
  display: none;
  z-index: 999;
  position: absolute;
  background-color: white;
  padding-left: 20px;
  overflow: auto;
  top: 60px;
  width: 400px;
  height: 400px;
  cursor: pointer;
}

.fa_search_icon {
  font-size: 20px;
  padding-right: 10px;
}

.search_a_denied {
  color: red;
  font-weight: 600;
  display: none;
  position: absolute;
  top: 16px;
  right: 125px;
  width: 120px;
}

@media screen and (max-width: 768px) {
  .glb_search_btn:hover {
    width: 85px !important;
  }
  .searchresult_cls {
    width: 300px;
    padding-left: 10px;
  }
}

/* write a code bala */
.serch-icon {
  color: #263eac;
  font-size: 12px !important;
  margin-bottom: -8px;
  font-weight: 500;
}

.form-search {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 1s;
  width: 32px;
  height: 32px;
  background: #263eac;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 5px;
  margin-top: 24px;
}

.form-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  line-height: 30px;
  outline: 0;
  border: 0;
  display: none;
  font-size: 1em;
  border-radius: 20px;
  padding: 0 20px;
}

.fafa {
  box-sizing: border-box;
  padding: 5px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  color: White;
  text-align: center;
  font-size: 1.2em;
  transition: all 1s;
  font-size: 21px;
}

.form-search:hover,
.form-search:valid {
  width: 300px;
  cursor: pointer;
  background: none;
}

.form-search:hover .form-input,
.form-search:valid .form-input {
  display: block;
}

.form-search:hover .fafa,
.form-search:valid .fafa {
  background: black;
  color: white;
}

@media screen and (max-width: 768px) {
  .form-search:hover,
  .form-search:valid {
    width: 120px;
    cursor: pointer;
    background: none;
  }
}

@media screen and (max-width: 992px) {
  .form-search:hover,
  .form-search:valid {
    width: 200px;
    cursor: pointer;
    background: none;
  }
}
