/* header */
.header_sm_device{
    display: none;
    text-align: center;
    background-color: black;
}
.header_sm_device_logo{
    color: #f5f5f5;
}
.user_icon{
    color: rgb(253 253 253);
    background: #263eac;
    border-radius: 30px;
    padding: 3px;
    font-size: 18px!important;
    
}
.user_p_menu:hover{
background-color: #263eac;
color: white;
}
.panel{
    box-shadow:0 1px 1px #263eac ;
}
.icon_size{
    font-size: 16px; /* RAMYA 18/MAR/2022 */
    color:#263eac

}
#setting_dropdown li{
    margin-bottom: 2px;
}
.menu_css{
    border-radius: 20px;
    width: 25rem;
    height: 50rem;
    margin-right: -60px;
    box-shadow: rgb(65 63 63 / 30%) 8px 6px 20px, rgb(0 0 0 / 22%) 2px 1px 2px;
}
/* mobile view */
@media screen and (max-width:768px){
    .header_sm_device{
        display: block;
        text-align: center;
        background-color: black;
    }  
    .comp_name_cent a{
        display: none;
    }
    .user_icon{
        color: rgb(253 253 253);
        background: #263eac;
        border-radius: 30px;
        padding: 3px;
        font-size: 18px!important;
        margin-top: 5px;
        
    }
   
    .menu_css{
        border-radius: 20px;
        width: 25rem;
        height: 50rem;
        margin-left: -150px;
        box-shadow: rgb(65 63 63 / 30%) 8px 6px 20px, rgb(0 0 0 / 22%) 2px 1px 2px;
    }
} 
ul.nav .icon-big{
    background: rgb(38, 62, 172);
    margin-top: -4px;
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    font-size: 20px;
}
.navbar-inverse .navbar-nav>li>a{
    padding: 12px 6px;
}
.user_profile ul{
    
}
