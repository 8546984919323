/* The write to bala */

.glogo-sec {
    width: 35px;
    height: 35px;
}

.gara-name {
    font-size: 24px;
    font-weight: 700;
    margin-left: 10px;
    margin-top: 10px;
}

.img-garageapp {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.logo-items h1 {
    margin-left: 5px;
    display: flex;
    font-weight: 900;
}

.logo-items h1 span {
    color: #1E73D7 !important;
}

.main-rightdiv {
    display: flex;
    float: right;
}

.main-rightdiv h1 {
    display: flex;
    font-weight: 900;
    text-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.main-rightdiv h1 span {
    color: #1E73D7 !important;
}


.sign-button {
    padding: 0 30px;
    font-size: 18px;
    font-weight: 900;
    color: white;
    margin: 20px 0 10px 10px;
    border: none;
    border-radius: 10px;
    background: rgb(21, 170, 217);
    background: linear-gradient(90deg, rgba(21, 170, 217, 1) 25%, rgba(119, 238, 238, 1) 100%);
    box-shadow: rgb(21, 170, 217) 0px 5px 15px;
}


.contain-box {
    margin-top: 65px;
    height: 500px;
    border-radius: 10px;
    width: 80%;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-left: auto !important;
    margin-right: auto !important;
    background-color: #F1F2F6;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.salider div div {
    border-radius: 20px !important;
}

.left-sec {
    padding: 20px 30px 30px 30px;
}

.text-singin {
    margin-top: 30px !important;
    font-size: 26px;
    display: flex;
    font-weight: 600;
}

.sign-app {
    align-items: center;
    display: flex;
    color: #828282;
    font-size: 14px;
    margin-bottom: 25px;
}

.sign-in-button {
    margin-top: 20px;
    padding: 10px 0;
    width: 70%;
    font-size: 18px;
    font-weight: 900;
    color: white;
    border: none;
    border-radius: 10px;
    background-color: #212b36;
}

.create-account{
    color: #23b76c;
    margin-left: 10px;
}


.forgot {
    text-decoration: underline;
}

.text-contant {
    display: flex;
    justify-content: end;
    margin-top: 10px;
    margin-bottom: 10px;
}

.slider-img {
    height: 100%;
    width: 100%;
    border-radius: 0 10px 10px 0;
    background: rgb(21, 170, 217);
    background: linear-gradient(rgba(21, 170, 217, 1) 25%, rgba(119, 238, 238, 1) 100%);
}

.img-size {
    width: 100%;
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
}


.float-left {
    float: left !important;
}


.rsis-container {
    height: 100% !important;
    width: 100% !important;
    border-radius: 0 10px 10px 0 !important;
}


.d-block {
    background-color: #828282;
}


.simple-slider {
    width: 100px !important;
}


.MuiSvgIcon-root {
    width: 2em !important;
    height: 2em !important;
}


.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 2em !important;
}

.css-152mnda-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 2em !important;
}

.Mouse-pointer {
    cursor: pointer;
}

/* Responivise media Qurias */
@media only screen and (max-width:768px) {
    .glogo-sec {
        width: 20px;
        height: 30px;
    }

    .logo-items h1 {
        font-size: 15px;
        margin-top: 8px;
        margin-left: 5px;
    }

    .main-rightdiv h1 {
        font-size: 15px;
        margin-top: 8px;
        margin-right: 5px;
    }

    .sign-button {
        padding: 5px;
        margin: 0;
        font-size: 14px;
    }

    .slider-img {
        height: 100% !important;
    }

}


@media only screen and (max-width:992px) {
    .slider-img {
        height: 375px;
    }

    .img-size {
        padding-top: 45px;
    }
}


@media only screen and (max-width:768px) {
    .salider {
        display: none !important;
    }
}


@media only screen and (max-width:992px) {
    .salider div {
        width: 97% !important;
        ;
        display: flex !important;
        text-align: center !important;
        border-radius: 0 0 10px 10px !important;
    }
}




@media only screen and (max-width: 480px) {
    .maincont-div[_ngcontent-cra-c76] {
        width: 100%;
    }
}



@media only screen and (max-width: 768px) {

    .maincont-div,
    .contain-box {
        width: 100% !important;
    }

    .left-sec {
        padding: 10px 15px 30px 15px;
    }
}

.wavebg {
    /*  background: url(./wave2.jpg); */
}


/* New changes in forgotpassword.js */
.container-shadow {
    margin-top: 55px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.text-center h2 {
    font-size: 36px;
    font-weight: 700;
    color: black;
    margin-bottom: 40px;
    margin-top: 40px !important;
}

.text-center h2::after {
    content: '';
    display: block;
    width: 30%;
    height: 4px;
    margin-left: 75px;
    background-color: #6695FF;
    text-align: center;
    border-radius: 2px;
    margin-top: 0.25rem;
}

.text-p {
    margin-top: 50px;
    margin-bottom: 30px;
    color: #A6A6A6;
    padding-left: 10px;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}



.button-div {
    justify-content: flex-end;
    float: right;
    margin-right: 35px;
}

.button-div p {
    color: #A6A6A6;
    font-size: 20px;
    padding-left: 10px;
    font-weight: 700;
    float: right;
}

.button-div p span {
    margin-left: 5px;
    color: #263EAC;
}



.reset-button {
    float: right;
    padding: 0 45px;
    font-size: 20px;
    font-weight: 900;
    height: 40px;
    color: white;
    margin: 10px 0 10px 10px;
    border: none;
    border-radius: 10px;
    background: rgb(21, 170, 217);
    background: linear-gradient(90deg, rgba(21, 170, 217, 1) 25%, rgba(119, 238, 238, 1) 100%);
    box-shadow: rgb(21, 170, 217) 0px 5px 15px;
}




/* OTP SECTION */


.text-center1 h2 {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    color: black;
    margin-bottom: 40px;
    margin-top: 40px !important;
}

.text-center1 h2::after {
    content: '';
    display: block;
    width: 30%;
    height: 4px;
    margin-left: 175px;
    background-color: #6695FF;
    text-align: center;
    border-radius: 2px;
    margin-top: 0.25rem;
}



@media only screen and (max-width: 768px) {

    .container-shadow {
        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-radius: 20px;
    }

    .text-center h2,
    .text-center1 h2 {
        font-size: 22px;
        margin-top: 0 !important;
        margin-bottom: 10px !important;
    }

    .text-center h2::after {
        content: '';
        display: block;
        width: 50%;
        height: 4px;
    }

    .text-center1 h2::after {
        content: '';
        display: block;
        width: 30%;
        height: 4px;
        margin-left: auto;
        margin-right: auto;
    }

    .text-p {
        font-size: 16px;
        margin-bottom: 0;
        margin-top: 0;
    }

    .button-div p {
        font-size: 16px;
    }

    .button-div {
        margin-right: 30px;
    }


}



.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    font-size: 20px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    font-size: 20px !important;
}

.left-sec .MuiFormControl-root{
    background-color: #f1f2f6 !important;
}
