.app {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, 200px);
}

span {
    display: inline-block;
}

span select input {
    display: block;
    position: relative;
    top: 3em;
}

span input {
    text-align: center;
}

span label {
    display: block;
    position: relative;
    top: 0em;
    text-align: center;
}

span button {
    display: block;
    position: relative;
    top: 0em;
    text-align: center;
}

#MaterialTheme {
    width: fit-content;
}

#timekeeperdiv {
    position: absolute;
    display: none;
    z-index: 10;
    margin-top: -20px;
}

.modal-dialog {
    margin-top: 120px;
}



.card2 {
    background: #f4f4f4;
    height: 441px;
    width: 275px;
    padding-left: 7px;
    padding-top: 40px;
    margin-bottom: 100px;
    box-shadow: 0px -1px 8px #8888887a;
    border-radius: 20px
}

.css-nakgy8-TimeKeeper {
    box-shadow: none !important;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

@media screen and (max-width: 768px) {
    #timekeeperdiv {
        position: absolute;
        display: none;
        z-index: 10;
        margin-top: -250px;
    }

    .modal-dialog {
        width: auto;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@media screen and (max-width: 1200px) {
    .modal-dialog {

        width: fit-content;
    }
}


.Toastify__toast-container--top-right {
    top: 5em;
}
