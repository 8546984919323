:root {
    --black: #000000;
    --green: #008000;
    --black-light-gray: rgb(0, 0, 0, 60%);
    --black-light-gray-h-line: rgb(0, 0, 0, 10%);
    --blue: #1e90ff;
    --Dark-blue: #263EAC;
    --white: #ffffff;
    --font-weight-700: 700;
    --font-weight-900: 900;
    --font-size-22: 22px;
    --font-size-20: 20px; 
    --font-size-30: 30px;
}


* {
    margin: 0px;
    padding: 0px;
}

.bg-images {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(image/garage-workshop.jpg);
    background-attachment: fixed;
    background-size: cover;
    font-family: new time roman;
    padding-bottom: 32px;
}

.logo-size {
    width: 75px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
}

.logo-name {
    font-family: roboto !important;
    font-size: 50px;
    color: white;
    font-weight: 900;
    font-family: system-ui;
    margin-top: 0;
    margin-bottom: 8;
}

.hr-class {
    color: white;
    border-top: 3px solid;
    opacity: 1;
    margin-top: 16px;
    margin-bottom: 16px;
}

.login-sec {
    color: white;
    margin-bottom: 10px;
    margin-top: 0;
}

.form-control-input {
    border: 0px;
    border-radius: 0px;
    margin-top: 10px;
    height: 45px;
    font-size: 23px;
    text-align: center;
    width: 400px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
}

.btn-danger-button{
    margin-top: 20px;
    width: 290px;
    font-size: 23px;
    /* background: linear-gradient(45deg, #4CC6C8, #80E2E4); */
    background: linear-gradient(-225deg, #5576ef, #7D6);
    border: 0px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    font-weight: 900;
    color: white;
}

.btn-danger-button:hover {
    background: linear-gradient(-225deg, #7D6, #5576ef);

}

.forget-sec {
    font-size: 20px;
    color: white;
    margin-top: 15px;
    margin-right: 18px;
    text-decoration: underline;
}


.btn-info-button {
    width: 130px;
    margin-bottom: 20px;
    border-radius: 100px;
    /* background: linear-gradient(45deg, #4CC6C8, #80E2E4); */
    background: linear-gradient(-225deg, #3976D7, #7951DE);
    /* background: linear-gradient(45deg, #3976D7, #7951DE); */
    color: white;
    font-weight: 800;
}

.center-block {
    background: transparent;
    box-shadow: -1px 1px 50px 10px black;
    margin-top: 30px;
    border-radius: 20px;
}

.btn-sign-in{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}



.input-icons i {
    position: absolute;
    display: flex;
    justify-content: right;
    font-size: 30px;
}

.input-icons {
    width: 100%;
    margin-bottom: 10px;
}

.icon {
    padding: 10px;
    min-width: 80px;
}


.a-tags {
    text-decoration: none;
}


.home-forget-section {
    display: flex;
    justify-content: space-between;
}

.home-icons {
    margin-top: 15px;
    font-size: 28px !important;
    color: white;
    margin-left: 35px;
    cursor: pointer;
}




/* For Desktop View */
@media screen and (min-width: 1024px) {
    .gfg-div {
        background-color: #63c971;
        color: #fff;
    }
}

/* For Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .gfg-div {
        width: 400px;
        height: 400px;
        background-color: orange;
        color: black;
    }
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
    .form-control-input {
        width: 330px;
    }

    .bg-images{
        padding-bottom: 250px;
    }

    .center-block{
        margin-top: 90px;
    }

}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) and (orientation: landscape) {
    .gfg-div {
        width: 400px;
        height: 200px;
        background-color: cyan;
        color: black;
    }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
    .gfg-div {
        width: 400px;
        height: 200px;
        background-color: chartreuse;
        color: black;
    }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
    .gfg-div {
        width: 400px;
        height: 400px;
        background-color: brown;
        color: black;
    }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
    .gfg-div {
        width: 400px;
        height: 400px;
        background-color: cornflowerblue;
        color: black;
    }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
    .gfg-div {
        width: 400px;
        height: 400px;
        background-color: darkgoldenrod;
        color: black;
    }
}
