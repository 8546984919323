.w3-progress-container {
    width: 100%;
    height: 1.5em;
    position: relative;
    background-color: #dadada;
}

.w3-progressbar {
    background-color: #ebebeb;
    height: 100%;
    position: absolute;
    line-height: inherit;
}

.w3-round-xlarge {
    border-radius: 16px !important
}

.diagnalstrips {
    background-image: repeating-linear-gradient(120deg, rgba(0, 0, 0, .5), rgba(255, 255, 255, .1) 7px, rgba(255, 255, 255, .1) 13px, rgba(255, 255, 255, .1) 15px) !important;
}


/* New changes add button added bala */

.div-add-btn {
    text-align: center;
}

.div-add-btn:hover {
    transform: scale(1.1);
}


.add-btn {
    background-color: #263eac;
    color: #ffff;
    font-size: 16px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    text-align: center;
}

.div-add-btn {
    text-align: center;
}

.div-add-btn:hover {
    transform: scale(1.1);
}

.Add-logo {
    width: 30px;
    margin-top: -30px;
    margin-left: -18px;
}


.repot_headercls h3 {
    margin-top: -35px;
    text-align: center;
}


.ReactTable .rt-thead .rt-resizable-header:last-child {
    text-align: center !important;
}

.text-center {
    text-align: center;
}

.modal-title {
    align-items: center;
    text-align: center;
    display: contents;
}

.modal-body {
    display: grid;
}


#quantityerror {
    color: red;
}

.table-styles {
    margin: auto;
    margin-top: 32px;
    margin-bottom: 5px;
}


.stock-text {
    margin-left: 10px !important;
    font-weight: 600;
}

.stock-width {
    width: 20px !important;
}

.stock-below {
    background-color: #f32821 !important;
}

.stock-above {
    background-color: #2196f3 !important;
}

.mt-10{
    margin-top: 10px;
}
