.form-container {
  background: #fff;
  border-radius: 20px;
  padding: 20px 80px;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.phonoicon {
  font-size: 14px;
}

h1 {
  text-align: center;
}

.message {
  font-weight: bold;
  color: brown;
}

.mandatory-fields {
  color: red;
}

.list-group-item {
  color: black !important;
  font-weight: 600 !important;
}

.list-group-item span {
  color: #263eac !important;
  width: 100%;
  overflow: hidden;
}

.list-margin {
  margin-left: 0 !important;
  padding: 0 !important;
}

.success-div {
  width: 50%;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.text-white {
  font-size: 28px !important;
  font-weight: 500;
  text-align: center;
  padding-top: 30px;
}

.success-div img {
  width: 300px;
  text-align: center;
  justify-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 768px) {
  .form-container {
    padding: 20px !important;
  }

  h1 {
    font-size: 18px !important;
  }
}

.form-control {
  width: 100%;
}


/* phono number section */

.PhoneInputCountryIcon {
  margin-top: -26px;
  width: 33px;
  height: 20px;
}

.PhoneInputCountrySelect {
  margin-left: 40px;
  height: 34px;
  border-radius: 3px;
  border-color: #ada9a9;
  width: 45%;
}

.PhoneInputInput {
  float: right;
  height: 34px;
  border-color: #ada9a9;
  border-radius: 3px;
  margin-top: -30px;
  width: 45%;
}

.error-position {
  float: right;
  margin-left: 202px;
}

@media only screen and (max-width: 768px) {

  .PhoneInputInput,
  .PhoneInputCountrySelect {
    width: 42%;
  }
}


/* Added new confirm pages style */

.profile-info {
  box-shadow: rgb(111 100 255 / 24%) 2px 2px 12px -2px, rgb(0 0 0 / 0%) 2px 1px 0px;
  display: flow-root;
  padding: 10px;
  transition: 1s;
  margin-bottom: 10px;
  border-radius: 10px;
  min-height: 350px;
  max-height: 400px;
}

.profile-user-info {
  display: table;
  width: 98%;
  width: calc(100% - 24px);
  margin: 0 auto
}

.profile-info-row {
  display: table-row;
}


.profile-info-name,
.profile-info-value {
  display: table-cell;
  border-top: 1px dotted #D5E4F1
}

.profile-info-name {
  text-align: left;
  padding: 6px 10px 6px 4px;
  font-weight: 600;
  color: #6f64ff;
  background-color: transparent;
  /* width: 110px; */
  width: 40%;
  vertical-align: middle
}

.profile-info-value {
  padding: 6px 4px 6px 6px;
  width: 60%;
  color: #000;
  font-weight: 500;
}

.profile-info-value>span+span:before {
  display: inline;
  content: ",";
  margin-left: 1px;
  margin-right: 3px;
  color: #666;
  border-bottom: 1px solid #FFF
}

.profile-info-value>span+span.editable-container:before {
  display: none
}

.profile-info-row:first-child .profile-info-name,
.profile-info-row:first-child .profile-info-value {
  border-top: none
}

.profile-user-info-striped {
  border: 1px solid #DCEBF7
}

.profile-user-info-striped .profile-info-name {
  color: #336199;
  background-color: #EDF3F4;
  border-top: 1px solid #F7FBFF
}

.profile-user-info-striped .profile-info-value {
  border-top: 1px dotted #DCEBF7;
  padding-left: 12px
}


@media only screen and (max-width:480px) {
  .profile-info-name {
    width: 80px
  }

  .profile-user-info-striped .profile-info-name {
    float: none;
    width: auto;
    text-align: left;
    padding: 6px 4px 6px 10px;
    display: block
  }

  .profile-user-info-striped .profile-info-value {
    margin-left: 10px;
    display: block
  }
}


@media only screen and (max-width:768px) {
  .profile-info {
    height: 100%;
  }
}

/* 

display: block;
display: -webkit-box;
max-width: 100%;
height: 43px;
margin: 0 auto;
font-size: 14px;
line-height: 1;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;



overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto; */




@media (max-width:991px) {
  .profile-info-value>span+span:before {
    display: inline;
    content: ",";
    margin-left: 1px;
    margin-right: 3px;
    color: #666;
    border-bottom: 1px solid #FFF;
    word-break: break-all;
  }
}

.head-text {
  text-align: center;
}


.span-word-break {
  width: 200px;
  overflow-wrap: break-word;
}
