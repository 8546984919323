/* header */
.header_sm_device {
    display: none;
    text-align: center;
    background-color: black;
}

.header_sm_device_logo {
    color: #f5f5f5;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
    background-color: white;
}

.home_icon {
    margin-left: 16px;
}

.dropdown-menu {
    cursor: pointer;
}

.pro-arrow {
    display: inline-block;
    border-style: solid;
    border-color: #adadad;
    border-width: 0 2px 2px 0;
    padding: 2.5px;
    vertical-align: middle;
    transition: transform 0.3s;
    transform: rotate(-45deg);
}

.menu_css li {
    padding: 2px;
    text-align: center;
    font-size: 14px;
    font-family: Poppins, sans-serif;
}

.outcircle {
    display: flex;
    justify-content: center;
    padding: 20px 0px;
    width: 100px;
    height: 100px;
    border: 1px solid;
    border-radius: 50px;
    margin-left: 90px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.circleuser {
    display: block;
    margin: 0 6px;
    width: 100px;
    height: 100px;
    line-height: 25px;
    text-align: center;
    border-radius: 50%;
    font-weight: bold;
    position: relative;
}

.circleimg {
    display: block;
    margin: 0 6px;
    width: 100px;
    height: 100px;
    line-height: 25px;
    text-align: center;
    border: 2px solid #9e9e9e;
    border-radius: 50%;
    color: #3d3d3d;
    background-color: transparent;
    font-size: 50px;
    font-weight: bold;
    position: relative;
    padding: 35px 5px;
}

.circleimg i {
    position: absolute;
    padding: 10px;
    color: #fff;
    background-color: #9e9e9e;
    border-radius: 50px;
    font-size: 25px;
    border: 5px solid #fff;
    bottom: -15px;
    right: -20px;
}

#userprofle_list {
    box-shadow: rgba(65, 63, 63, 0.3) 8px 6px 20px, rgba(0, 0, 0, 0.22) 2px 1px 2px;
    display: flow-root;
    transition: 0.5s;
    margin-bottom: 10px;
    border-radius: 10px;
    height: 55rem;
    width: 20rem;
    position: fixed;
    right: 20px;
    top: 80px;
    background-color: #ffffff;
    margin-right: 0px;
}

.navbar_company_name {
    background-color: white;
    display: flex;
    transition: width .3s ease-in-out;
    display: block;
    float: left;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    width: 250px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 0 15px;
    font-weight: 300;
    overflow: hidden;
}

.navbar_company_name_sm_device {
    display: none;
}

.navbar_css {
    position: fixed;
    z-index: 1050;
    height: 50px;
    width: 100%;
    color: #263eac;
}

.btn-toggle {
    margin: 10px 23px;
    color: #263eac;
}

.pro-sidebar>.pro-sidebar-inner>img.sidebar-bg {
    opacity: 0 !important;
    background-color: wheat !important;

}

.pro-sidebar>.pro-sidebar-inner {
    background: #263eac !important;
    border-radius: 0px 50px 0px 0px;
    margin-top: 50px;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px !important;
    cursor: pointer;
}

.pro-sidebar .pro-menu .pro-menu-item:hover {
    background: transparent;
    transition: 0s;
}

.navbar {
    border-radius: 0px;
    margin-bottom: 0px;
}

.submenu_css {
    background: #ffffff;
    margin-left: -20px;
    color: #263eac;
    padding-top: 15px;
    padding-bottom: 15px;
    list-style: none;
    margin-top: -15px;
    margin-bottom: -15px;
    padding-left: 20px;
    font-weight: 500;
}

.submenu_css li:hover {
    background-color: #263eac !important;
    color: white !important;
    padding-left: -20px;
    cursor: pointer;
}

.comp_name_cent a {
    float: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
}

.pro-sidebar.collapsed .pro-item-content {
    display: none;
}

.pro-sidebar.collapsed .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper .pro-icon {
    width: 70px;
}

.pro-sidebar.collapsed .panel-group {
    margin-left: 0px;
}

.dasdboard_div {
    background-color: #00a6a4;
    padding: 9px 0px;
}

.fa-chart {
    border: none;
    display: inline-block;
    padding-left: 30px;
    font-size: 20px;
    line-height: 25pt;
    padding-right: 20px;
}

.span_dash {
    font-size: 20px;
    opacity: 1;
    transition: all 300ms linear;
    font-family: "poppins", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 30px;
    white-space: nowrap;
    text-transform: uppercase;
}

.user_profile[aria-expanded="false"]::before,
.user_profile[aria-expanded="true"]::before {
    content: '';
    display: block;
    position: absolute;
    padding-top: 10px;
    right: 20px;
    font-family: 'Glyphicons Halflings';
    font-size: 0.6em;
}

.user_p_menu {
    background: transparent;
}

.userprofile_css {
    height: 100%;
}

.wrapper {
    display: flex;
    align-items: stretch;
}

#sidebar {
    width: 0px;
    min-height: 100%;
    position: fixed;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-image: url("./images/backimg.png") !important;
    transition: all 0.3s;
}


#side-menu {
    top: 50px;
    position: absolute;
}

.side_menu_image {
    z-index: 0;
    left: 0;
    display: block;
    backdrop-filter: green !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}

.screen_overlay {
    opacity: 0.8;
    display: block;
    height: 100vh;
    overflow: auto;
}

.contentRender {
    margin-top: 50px;
    height: 100%;
    width: 100%;
    display: block;
    background-color: rgb(247, 249, 252);
}

.usermenu li {
    list-style-type: none;
}

.usermenu.active {
    margin-bottom: 10px;
    border-radius: 10px;
    display: flow-root;
    height: 90%;
    width: 18%;
    position: fixed;
    right: 20px;
    background-color: #ffffff;
    margin-right: 0px;
    padding: 0px;
}

.contentRender.active {
    width: calc(100% - 25%) !important;
}

.usermenu {
    display: none;
    margin-right: -15%;
}

.dashboard_List {
    opacity: 1;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
    border-bottom: 1px solid rgb(234 237 250);
}

/*
    ADDITIONAL DEMO STYLE, NOT IMPORTANT TO MAKE THINGS WORK BUT TO MAKE IT A BIT NICER :)
*/
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
@import "https://fonts.googleapis.com/css?family=poppins:300,400,500,600,700";

body {
    font-family: 'poppins', sans-serif;
}

p {
    font-family: 'poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

#sidebar {
    /* don't forget to add all the previously mentioned styles here too */
    background: #7386D5;
    color: #fff;
    transition: all 0.3s;
    box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
}

#sidebar ul.components {
    padding: 20px 0;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    cursor: pointer;
}

#sidebar ul li a:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(65, 63, 63);
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;

}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 32px !important;
    background: rgb(255, 255, 255);
}

a[aria-expanded="false"]::before,
a[aria-expanded="true"]::before {
    content: '\e259';
    display: block;
    position: absolute;
    padding-top: 10px;
    right: 20px;
    font-family: 'Glyphicons Halflings';
    font-size: 0.6em;
}

a[aria-expanded="true"]::before {
    content: '\e260';
}

.sidebar_Title {
    font-size: 14px;
    font-family: 'poppins', sans-serif;
    ;
    font-weight: 100;

}

.i_sidebar {
    font-size: 20px;
    padding-right: 15px;
    border: none;
    display: inline-block;
}

.sbar_smenu_font {
    font-size: 13px;
    font-weight: 100;
    margin-left: 14px;
}

.purchase {


    margin-left: 4px;
}

.fa_submenu {
    display: none !important;
}

/* help file */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.settings_Top_Submenu {
    font-size: 12px;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    padding-left: 10px;
}

.glyphicon-spin {
    font-size: 20px;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

/* mobile view */
@media screen and (max-width:768px) {
    .navbar_company_name {
        display: none;
    }

    .navbar_company_name_sm_device {
        display: block;
    }

    .navbar_css {
        margin-left: 0px;
    }

    .nav_brand_client_sm {
        display: none;
    }

    .pro-sidebar>.pro-sidebar-inner {
        background: #263eac !important;
        border-radius: 0px 50px 0px 0px;
        margin-top: 100px;
    }

}

.goog {
    padding: 30px;
}

/* mobile view */
@media screen and (max-width:768px) {

    .goog {
        padding: 10px;
        margin-left: -60px;
    }

    .pro-sidebar {
        color: #000000;
        height: 100%;
        width: 270px;
    }

    .pro-sidebar.md {
        left: 0px !important;
        padding-bottom: 76px;
    }

    .pro-sidebar.md.active {
        display: none !important;
        transition: 0.5s ease;
    }

    .pro-sidebar.collapsed .collapsed_group .panel-collapse {
        height: auto !important;
        min-width: 220px;
        max-width: 260px !important;
        border-left: 5px solid #263eac;
        position: inherit !important;
        margin-top: 0px !important;
    }

    .pro-sidebar.md.toggled {
        width: 270px !important;
        left: 0px !important;
        min-width: 270px;
    }

    .pro-sidebar.md.collapsed {
        width: 0px;
        left: 0px !important;
        min-width: 0px;
    }

    .pro-sidebar.collapsed {
        width: 0px;
        min-width: 0px;
    }

    */ .header_sm_device {
        display: block;
        text-align: center;
        background-color: black;
        padding: 7px 0px;
    }

    .main_Header_sm {
        position: fixed;
        width: 100%;
        z-index: 50;
    }

    .contentRender_sm {
        margin-left: 0px !important;
        margin-top: 100px;
        width: 100% !important;
    }

    .contentRender_sm::-webkit-scrollbar {

        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    #side-menu {
        top: 90px;
        position: absolute;
    }

    .pro-sidebar {
        top: 88px;
    }

    .usermenu.active {
        margin-bottom: 10px;
        border-radius: 10px;
        display: flow-root;
        height: 90%;
        width: 70%;
        position: fixed;
        right: 20px;
        background-color: #ffffff;
        margin-right: 0px;
        padding: 0px;
    }

    .outcircle {
        margin-left: 70px;
    }

    .usermenu {
        display: none;
        margin-right: -15%;
    }
}



.pro-sidebar {
    color: #000000;
    height: auto;
    width: 270px;
    min-width: 270px;
    text-align: left;
    transition: width, left, right, 0.3s;
    position: relative;
    z-index: 1009;
    top: 50px;
    margin-top: -50px;

}

.goog-te-banner-frame {
    display: none !important;
    top: 0px;
}

body {
    top: 0px !important;
}
