.jobcard_icon{
    top: -40px;
    position: absolute;
   
    color: #fff;
    height: 50px;
    border-radius: 20px;
    width: 50px;
    background-image: linear-gradient(to right bottom, #263eac, #4b5cc0, #6b7ad3, #8b99e6, #abb9f8);
    /* padding: 7px; */
    box-shadow: 1px 2px rgb(111 100 255 / 32%);
   
   
}
.tiles_text{
    font-size: 20px;
    font-weight: 600;
}
.ReactTable .rt-table{
    border-radius: 10px;
}
.ReactTable{
border:1px solid rgb(234 237 250);
border-radius: 10px;
box-shadow:rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem
}
/* .custom_bg{
    background-color: #6f64ff;
    padding: 20px;
    font-size: 20px;
} */

.link_text{
    color:blue;
    margin-left: -40px;
    margin-right: 10px;
    float: left;
    cursor: pointer;
}