:root {
  --black: #000000;
  --green: #008000;
  --black-light-gray: rgb(0, 0, 0, 60%);
  --black-light-gray-h-line: rgb(0, 0, 0, 10%);
  --blue: #1e90ff;
  --Dark-blue: #263EAC;
  --white: #ffffff;
  --font-weight-700: 700;
  --font-weight-900: 900;
  --font-size-22: 22px;
  --font-size-20: 20px; 
  --font-size-30: 30px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pointer {
  cursor: pointer;
}

.primaer-color {
  color: #263eac;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}



.mandatory-fields {
  color: red;
}
