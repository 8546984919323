.card{
   
    box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
    border-radius: 9px;
}
.card_tiles{
    box-shadow: rgb(181 177 177 / 8%) 4px 1px 18px 2px, inset rgb(0 0 0 / 22%) 0px -1px 0px 0px ;
    border-radius: 18px;
}

.card1{
    height: 200px;
}
.card_heading{
    color:rgb(38, 62, 172);
    font-size: 20px;
}